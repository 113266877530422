import React, { useState, useEffect, useCallback } from "react";
import ReactGA from "react-ga4";
import axios from "axios";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  Link,
  InputAdornment,
  Modal,
  ThemeProvider,
  createTheme,
  IconButton,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import {
  EnvelopeSimple,
  Key,
  Eye,
  ArrowRight,
  CheckCircle,
  XCircle,
  EyeClosed,
  SpinnerGap,
} from "phosphor-react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { ReactComponent as LogoSVG } from "../assets/svg/logo6.svg";
import { ReactComponent as BackgroundSVG } from "../assets/svg/vector.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0EA996",
    },
    error: {
      main: "#f44336",
    },
  },
  typography: {
    fontFamily: "aileron, Arial, sans-serif",
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&$focused $notchedOutline": {
            borderColor: "#0EA996",
          },
          "&.Mui-error $notchedOutline": {
            borderColor: "#f44336",
          },
        },
        notchedOutline: {
          borderColor: "#ccc",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&$focused": {
            color: "#0EA996",
          },
          "&.Mui-error": {
            color: "#f44336",
          },
        },
      },
    },
  },
});

const Login = React.memo(() => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const handleOpenTerms = () => setOpenTerms(true);
  const handleCloseTerms = () => setOpenTerms(false);
  const handleOpenPrivacy = () => setOpenPrivacy(true);
  const handleClosePrivacy = () => setOpenPrivacy(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = new URLSearchParams();
      data.append("username", email);
      data.append("password", password);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_V2}/auth/token`,
        data
      );
      if (response.data && response.data.access_token) {
        localStorage.setItem("access_token", response.data.access_token);
        ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
        ReactGA.set({ user_id: response.data.access_token });
        ReactGA.event({
          category: "User",
          action: "Login",
          label: response.data.access_token,
        });
        window.location.href = "/";
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setLoginError(true);
        setErrorMessage("Incorrect work email or password, please try again.");
      } else {
        setLoginError(true);
        setErrorMessage("Incorrect work email or password, please try again.");
      }
      console.error("Login error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = useCallback(
    (setter) => (e) => {
      setter(e.target.value);
      if (loginError) {
        setLoginError(false);
        setErrorMessage("");
      }
    },
    []
  );
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const isFormFilled = email.length > 0 && password.length > 0;
  const isEmailStart = email.length > 0;
  const isPasswordStart = password.length > 0;
  const opacityEmail = isEmailStart ? 1 : 0.5;
  const opacityPassword = isPasswordStart ? 1 : 0.5;

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [expiredSnackbar, setExpiredSnackbar] = useState(false);
  const [accountExpired, setAccountExpired] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const resetStatus = searchParams.get("reset");
    if (resetStatus === "send") {
      setOpenSnackbar(true);
    }
    if (resetStatus === "success") {
      setSuccessSnackbar(true);
    }
    if (resetStatus === "expired") {
      setExpiredSnackbar(true);
    }
    if (resetStatus === "accountExpired") {
      setAccountExpired(true);
    }
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="ms"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#132B24",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <LazyLoadComponent>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              zIndex: 0,
            }}
          >
            <BackgroundSVG style={{ width: "100%", height: "auto" }} />
          </Box>
        </LazyLoadComponent>
        {!openTerms && !openPrivacy && (
          <Card
            sx={{
              width: 446,
              height: 498,
              borderRadius: "12px",
              padding: "32px 24px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              position: "relative",
              zIndex: 1,
              animation: "fadeInUp 1s ease-in-out",
            }}
          >
            <CardContent sx={{ display: "flex", flexDirection: "column" }}>
              <LogoSVG style={{ width: 120, height: 60 }} />
              <Typography
                component="h1"
                variant="h5"
                sx={{ mb: 2, fontWeight: 600, fontSize: "24px" }}
              >
                Sign In To Immortal
              </Typography>
              <Box
                component="form"
                sx={{ width: "100%" }}
                onSubmit={handleLogin}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  placeholder="Work Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={handleChange(setEmail)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EnvelopeSimple
                          size={24}
                          style={{ opacity: opacityEmail, color: "#132B24" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: loginError ? "#F64747" : "#C4C4C4", // Ensure borderColor does not change on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#0EA996",
                      },
                      // "&.Mui-error fieldset": {
                      //   borderColor: "#0EA996 !important",
                      // }
                    },
                    marginBottom: "1px",
                  }}
                  error={loginError}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange(setPassword)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Key
                          size={24}
                          style={{ opacity: opacityPassword, color: "#132B24" }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          onClick={togglePasswordVisibility}
                          sx={{
                            padding: 0,
                            minWidth: "auto",
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            "&:hover": {
                              backgroundColor: "transparent",
                              boxShadow: "none",
                            },
                            "&:active": {
                              backgroundColor: "transparent",
                              boxShadow: "none",
                            },
                            "&:focus": {
                              backgroundColor: "transparent",
                              boxShadow: "none",
                              outline: "none",
                            },
                            "& .MuiTouchRipple-root": {
                              display: "none",
                            },
                          }}
                        >
                          {showPassword ? (
                            <EyeClosed
                              size={24}
                              style={{
                                opacity: 0.5,
                                color: "#132B24",
                              }}
                              weight="fill"
                            />
                          ) : (
                            <Eye
                              size={24}
                              style={{
                                opacity: opacityPassword,
                                color: "#132B24",
                              }}
                              weight="fill"
                            />
                          )}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  error={loginError}
                  helperText={loginError ? errorMessage : ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: loginError ? "#F64747" : "#C4C4C4", // Ensure borderColor does not change on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#0EA996",
                      },
                    },
                    "& .MuiFormHelperText-root": {
                      fontFamily: "Aileron, sans-serif",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "22px",
                      textAlign: "left",
                      color: "#F64747",
                    },
                  }}
                />
                <Link
                  component="button"
                  type="button"
                  variant="body2"
                  sx={{
                    display: "block",
                    textAlign: "center",
                    textTransform: "none",
                    color: "#0EA996",
                    textDecoration: "underline",
                    fontWeight: 600,
                    mb: 2,
                    mt: 2,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/SendResetPassword";
                  }}
                >
                  Forget your password?
                </Link>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disableRipple
                  sx={{
                    mt: 2,
                    mb: 2,
                    height: "48px",
                    backgroundColor: isFormFilled ? "#0EA996" : "#F2F8F7",
                    color: isFormFilled ? "#FFFFFF" : "#73BDB0",
                    boxShadow: "none",
                    fontWeight: 600,
                    "&:hover": {
                      backgroundColor: isFormFilled ? "#0C9887" : "#F2F8F7",
                      color: isFormFilled ? "#FFFFFF" : "#73BDB0",
                      boxShadow: "none",
                    },
                  }}
                  endIcon={
                    loading ? null : (
                      <ArrowRight
                        size={24}
                        color={isFormFilled ? "#FFFFFF" : "#73BDB0"}
                      />
                    )
                  }
                  disabled={loading}
                >
                  {loading ? (
                    <SpinnerGap
                      size={24}
                      color="#FFFFFF"
                      style={{ animation: "spin 1s linear infinite" }}
                    />
                  ) : (
                    "Sign in"
                  )}
                </Button>
                <style>
                  {`
                      @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                      }
                      @keyframes fadeInUp {
                        0% { opacity: 0; transform: translateY(20px); }
                        100% { opacity: 1; transform: translateY(0); }
                      }
                  `}
                </style>
              </Box>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  mt: 2,
                  textAlign: "center",
                  fontSize: "13px",
                  fontWeight: 600,
                  lineHeight: "16px",
                  letterSpacing: "0.01em",
                }}
              >
                By signing in, you agree to our
                <Link
                  //   onClick={handleOpenPrivacy}
                  target="_blank"
                  href="https://docs.google.com/document/d/17MDis1MHlA60R7fI02X3yq3pHUSRNgIlB8qN0tcjU7M/edit?usp=sharing"
                  sx={{
                    color: "#0EA996",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  Privacy Policy
                </Link>
              </Typography>
            </CardContent>
          </Card>
        )}
        <Modal
          open={openTerms}
          onClose={handleCloseTerms}
          aria-labelledby="terms-of-service-modal-title"
          aria-describedby="terms-of-service-modal-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "80%",
              height: "525px",
              bgcolor: "background.paper",
              p: 4,
              borderRadius: 2,
              overflowY: "auto",
              position: "relative",
              boxShadow: 24,
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleCloseTerms}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              id="terms-of-service-modal-title"
              variant="h6"
              sx={{ mb: 2 }}
            >
              Terms of Service
            </Typography>
            <Typography id="terms-of-service-modal-description" variant="body1">
              Lorem ipsum dolor sit amet consectetur. Dolor mauris id leo cursus
              tellus dolor iaculis. Diam in aliquam in sit cras integer tortor
              tincidunt faucibus. Euismod morbi vel enim sollicitudin fermentum.
            </Typography>
            {/* Add more content as needed */}
          </Box>
        </Modal>
        <Modal
          open={openPrivacy}
          onClose={handleClosePrivacy}
          aria-labelledby="terms-of-service-modal-title"
          aria-describedby="terms-of-service-modal-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "80%",
              height: "525px",
              bgcolor: "background.paper",
              p: 4,
              borderRadius: 2,
              overflowY: "auto",
              position: "relative",
              boxShadow: 24,
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleClosePrivacy}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              id="terms-of-service-modal-title"
              variant="h6"
              sx={{ mb: 2 }}
            >
              Privacy Policy
            </Typography>
            <Typography id="terms-of-service-modal-description" variant="body1">
              Lorem ipsum dolor sit amet consectetur. Dolor mauris id leo cursus
              tellus dolor iaculis. Diam in aliquam in sit cras integer tortor
              tincidunt faucibus. Euismod morbi vel enim sollicitudin fermentum.
            </Typography>
            {/* Add more content as needed */}
          </Box>
        </Modal>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#0EA996",
              width: "521px",
              height: "76px",
              padding: "2 4px",
              marginTop: "110px",
              display: "flex",
              alignItems: "center",
              borderRadius: "4px",
            },
          }}
        >
          <SnackbarContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              color: "#FFFFFF",
              fontSize: "16px",
              fontWeight: "normal",
            }}
            message={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CheckCircle
                  size={50}
                  color="#FFFFFF"
                  style={{ paddingRight: "12px" }}
                  weight="fill"
                />{" "}
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  If your email address exists in our database, you will receive
                  a password recovery link at your email address in a few
                  minutes.
                </Typography>
              </Box>
            }
          />
        </Snackbar>
        <Snackbar
          open={successSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#0EA996",
              width: "521px",
              height: "56px",
              padding: "2 4px",
              marginTop: "20%",
              display: "flex",
              alignItems: "center",
              borderRadius: "4px",
            },
          }}
        >
          <SnackbarContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              color: "#FFFFFF",
              fontSize: "16px",
              fontWeight: "normal",
            }}
            message={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CheckCircle
                  size={30}
                  color="#FFFFFF"
                  style={{ paddingRight: "12px" }}
                  weight="fill"
                />{" "}
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  Your password was successfully updated, Welcome to sign in.
                </Typography>
              </Box>
            }
          />
        </Snackbar>
        <Snackbar
          open={expiredSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#F64747",
              width: "521px",
              height: "56px",
              padding: "2 4px",
              marginTop: "20%",
              display: "flex",
              alignItems: "center",
              borderRadius: "4px",
            },
          }}
        >
          <SnackbarContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              color: "#FFFFFF",
              fontSize: "16px",
              fontWeight: "normal",
            }}
            message={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <XCircle
                  size={30}
                  color="#FFFFFF"
                  style={{ paddingRight: "12px" }}
                  weight="fill"
                />{" "}
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  The link has expired, please reset your password
                </Typography>
              </Box>
            }
          />
        </Snackbar>
        <Snackbar
          open={accountExpired}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#F64747",
              width: "521px",
              height: "56px",
              padding: "2 4px",
              marginTop: "20%",
              display: "flex",
              alignItems: "center",
              borderRadius: "4px",
            },
          }}
        >
          <SnackbarContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              color: "#FFFFFF",
              fontSize: "16px",
              fontWeight: "normal",
            }}
            message={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <XCircle
                  size={30}
                  color="#FFFFFF"
                  style={{ paddingRight: "12px" }}
                  weight="fill"
                />{" "}
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  Account Expired
                </Typography>
              </Box>
            }
          />
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
});

export default Login;
